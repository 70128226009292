import React from "react";
import "./Team.css";
import team1 from "../../Assets/team-member.jpg";
import team2 from "../../Assets/team-member2.jpg";
import { FaLinkedinIn } from "react-icons/fa";
import { AiFillGithub } from "react-icons/ai";
import { BsLink45Deg } from "react-icons/bs";
import { Element } from 'react-scroll';
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();


const Team = () => {
  return (
      <Element name="team-scroll-div">
        <div id="team" className="team-section">
          <h6
            data-aos="fade-down"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
          >
            who made it
          </h6>
          <h1
            data-aos="flip-down"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          >
            Meet the team
          </h1>

          <div className="team-container">
            <div
                className={"memberCard"}
                data-aos="fade-up-right"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1000"
            >
              <img src={team1} alt="" />
              <div className="team-info">
                <h4>Victor Xirau</h4>
                <p>Founder · App & Web Developer and Designer</p>
                <ul>
                  <li>
                    <a href="https://victorxirau.tech" target="_blank"  rel="noreferrer" >
                      <BsLink45Deg />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/in/victor-xirau"
                      target="_blank"  rel="noreferrer"
                    >
                      <FaLinkedinIn />
                    </a>
                  </li>
                  <li>
                    <a href="https://github.com/VXGamez" target="_blank"  rel="noreferrer" >
                      <AiFillGithub />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div
                className={"memberCard"}
                data-aos="fade-up-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1000"
            >
              <img src={team2} alt="" />
              <div className="team-info">
                <h4>Bernat Segura</h4>
                <p>Co-Founder · Backend Engineer</p>
                <ul>
                  <li>
                    <a
                      href="https://www.linkedin.com/in/bernat-segura"
                      target="_blank"  rel="noreferrer"
                    >
                      <FaLinkedinIn />
                    </a>
                  </li>
                  <li>
                    <a href="https://github.com/bernatsc" target="_blank"  rel="noreferrer" >
                      <AiFillGithub />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Element>
  );
};

export default Team;
