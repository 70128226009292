import React from "react";
import "./Testimonial.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { Element } from 'react-scroll';

AOS.init();

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "2px",
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        background: "rgb(255, 164, 1)",
      }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (

    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "2px",
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        background: "rgb(255, 164, 1)",
      }}
      onClick={onClick}
    />
  );
}

const Testimonial = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
      <Element name="whatUsersSay-scroll-div">
    <div id="testimonial" className="tesimonial-section">
      <div className="testimonial-content">
        <h6
          data-aos="fade-down"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1000"
        >
          don't believe us?
        </h6>
        <h1
          data-aos="flip-down"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
        >
          see what users say
        </h1>
      </div>

      <Slider {...settings} className="slider-container">
        <div className="single_testimonial_slider">
          <div
            data-aos="fade-up"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
          >
            <h2>R</h2>
          </div>
          <div>
            <h4
              data-aos="fade-up"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1200"
            >
              Roger
            </h4>
            <span
              data-aos="fade-up"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1300"
            >
              Spain
            </span>
            <p
              data-aos="fade-up"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1400"
            >
              I didn't expect this app to work but as soon as I got it I
              calculated a couple trips and was blown away. I would fully
              recommend this app.
            </p>
          </div>
        </div>

        <div className="single_testimonial_slider">
          <div
            data-aos="fade-up"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
          >
            <h2>BG</h2>
          </div>
          <div>
            <h4
              data-aos="fade-up"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1200"
            >
              Borja Gil
            </h4>
            <span
              data-aos="fade-up"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1300"
            >
              Spain
            </span>
            <p
              data-aos="fade-up"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1400"
            >
              This app is not only beautiful, but works. I really enjoy the
              instant trips feature. It's fast and easy to use. I would
              recommend this app to anyone who wants to travel.
            </p>
          </div>
        </div>

        <div className="single_testimonial_slider">
          <div
            data-aos="fade-up"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
          >
            <h2>PB</h2>
          </div>
          <div>
            <h4
              data-aos="fade-up"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1200"
            >
              Pedro Benítez
            </h4>
            <span
              data-aos="fade-up"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1300"
            >
              Spain
            </span>
            <p
              data-aos="fade-up"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1400"
            >
              I downloaded this app and was like “is it really going to help me
              travel that much?” And it did. I loved it!
            </p>
          </div>
        </div>
      </Slider>
    </div>
      </Element>
  );
};

export default Testimonial;
