import React from "react";
import "./DownloadApp.css";
import { AiFillApple, AiOutlineGoogle } from "react-icons/ai";
import AOS from "aos";
import "aos/dist/aos.css";
import { Element } from 'react-scroll';
AOS.init();

const DownloadApp = () => {
  return (
      <Element name="download-scroll-div">
        <div id="download" className="ambassadors-section download-app-section">
          <div className="ambassadors-content">
            <h6
              data-aos="fade-down"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"

            >
              get to calculating
            </h6>
            <h1
              data-aos="flip-down"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
            >
              download today
            </h1>
            <div className="download-app-container ">
              <a
                href="https://apps.apple.com/us/app/dyvide/id1622209712"
                target="_blank" rel="noreferrer"

              >
                <div
                  data-aos="flip-up"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="1000"
                  className={"iosButton"}
                >
                  <AiFillApple />
                  <div>
                    <p>Download on the</p>
                    <h4>App store</h4>
                  </div>
                </div>
              </a>

              <a
                href="https://play.google.com/store/apps/details?id=com.projectk.dyvide"
                target="_blank" rel="noreferrer"
              >
                <div
                  data-aos="flip-up"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="1000"
                >
                  <AiOutlineGoogle />
                  <div>
                    <p>Get it on</p>
                    <h4>Google play</h4>
                  </div>
                </div>
              </a>
            </div>
            <div className="footer-p">
              <p>© Project K {(new Date().getFullYear())}. All rights reserved</p>
            </div>
          </div>
        </div>
      </Element>
  );
};

export default DownloadApp;
