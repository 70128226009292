import "./SideMenu.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from 'react-scroll';
import {useEffect, useState} from "react";
AOS.init();

const SideMenu = () => {
    const toggleMenu = () => {
        if (document.getElementById('toggle').classList.contains('active')) {
            setTimeout(function(){
                document.getElementById("span-top").classList.toggle("buttonAnimation")
                document.getElementById("span-middle").classList.toggle("buttonAnimation")
                document.getElementById("span-bottom").classList.toggle("buttonAnimation")
            }, 400);
        }else{
            document.getElementById("span-top").classList.toggle("buttonAnimation")
            document.getElementById("span-middle").classList.toggle("buttonAnimation")
            document.getElementById("span-bottom").classList.toggle("buttonAnimation")
        }

        document.getElementById('toggle').classList.toggle('active');
        document.getElementById('overlay').classList.toggle('open');

    };

    const [setShadow] = useState(false);

    useEffect(() => {
        const handleShadow = () => {
            if (window.scrollY >= 150) {
                document.getElementById("toggle").classList.add("menuButtonBackground")
            } else {
                document.getElementById("toggle").classList.remove("menuButtonBackground")
            }
        };
        window.addEventListener("scroll", handleShadow);
    }, [setShadow]);


    return (
        <div>
            <div className="button_container" id="toggle" onClick={toggleMenu}><span id="span-top" className="top"></span><span id="span-middle" className="middle"></span><span
                id="span-bottom" className="bottom"></span></div>
            <div className="overlay" id="overlay">
                <nav className="overlay-menu">
                    <ul>
                        <li><Link to="about-scroll-div" smooth={true} duration={1000} offset={-10} delay={500}><button onClick={() => toggleMenu()}>About</button></Link></li>
                        <li><Link to="features-scroll-div" smooth={true} duration={1000} offset={-10} delay={500}><button onClick={() => toggleMenu()}>Features</button></Link></li>
                        <li><Link to="upcomingFeatures-scroll-div" smooth={true} duration={1000} offset={-800} delay={500}><button onClick={() => toggleMenu()}>Upcoming Features</button></Link></li>
                        <li><Link to="screenshots-scroll-div" smooth={true} duration={1000} offset={80} delay={500}><button onClick={() => toggleMenu()}>Screensots</button></Link></li>
                        <li><Link to="whatUsersSay-scroll-div" smooth={true} duration={1000} offset={100} delay={500}><button onClick={() => toggleMenu()}>What Users Say</button></Link></li>
                        <li><Link to="team-scroll-div" smooth={true} duration={1000} offset={-100} delay={500}><button onClick={() => toggleMenu()}>Team</button></Link></li>
                        <li><Link to="download-scroll-div" smooth={true} duration={1000} offset={-50} delay={500}><button onClick={() => toggleMenu()}>Download</button></Link></li>
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default SideMenu;
