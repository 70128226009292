import React from "react";
import About from "./About/About";
import Ambassadors from "./Ambassadors/Ambassadors";
import DownloadApp from "./DownloadApp/DownloadApp";
import Features from "./Features/Features";
import Footer from "./Footer/Footer";
import GetApp from "./GetApp/GetApp";
import Header from "./Header/Header";
import Screenshots from "./Screenshots/Screenshots";
import Team from "./Team/Team";
import Testimonial from "./Testimonial/Testimonial";
import SideMenu from "./Menu/SideMenu";

const Home = () => {
  return (
    <>
      <Header />
      <GetApp />
      <About />
      <Features />
      <Ambassadors />
      <Screenshots />
      <Testimonial />
      <Team />
      <DownloadApp />
      <Footer />
      <SideMenu/>
    </>
  );
};

export default Home;
