import React from "react";
import "./Ambassadors.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { Element } from 'react-scroll';
AOS.init();

const Ambassadors = () => {
  return (
      <Element name="upcomingFeatures-scroll-div">
    <div id="upcoming_features" className="ambassadors-section">
      <div className="ambassadors-content">
        <h6
          data-aos="fade-down"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1000"
        >
          dyvide ambassadors
        </h6>
        <h1
          data-aos="flip-down"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
        >
          become an <br /> ambassador
        </h1>
        <p
          data-aos="flip-down"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
        >
          Share your referral link to 20 friends, and when they all register
          you'll get access to tons of exclusive features such as: suggesting
          new ideas, having a look at the current development pipeline, having a
          distinctive on the app, and tons of others to come!
        </p>
      </div>
    </div>
      </Element>
  );
};

export default Ambassadors;
