import React from "react";
import "./Screenshots.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import screenshot1 from "../../Assets/header2-upscaled.jpeg";
import screenshot2 from "../../Assets/screen2.jpeg";
import screenshot3 from "../../Assets/screen3.jpeg";
import screenshot4 from "../../Assets/header3.jpeg";
import screenshot5 from "../../Assets/screen5.jpeg";
import screenshot6 from "../../Assets/screen6 copia.jpeg";
import AOS from "aos";
import "aos/dist/aos.css";
import { Element } from 'react-scroll';
AOS.init();

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "2px",
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        background: "rgb(255, 164, 1)",
      }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "2px",
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        background: "rgb(255, 164, 1)",
      }}
      onClick={onClick}
    />
  );
}

const Screenshots = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
      <Element name="screenshots-scroll-div">
    <div id="screenshots" className="screenshot-section">
      <h6
        data-aos="fade-down"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="1000"
      >
        app preview
      </h6>
      <h1
        data-aos="flip-down"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="2000"
      >
        screenshots
      </h1>

        <div className={"mobileVersion"}>
            <Slider {...settings}>
                <div
                    className="single_screenshot_slider"
                    data-aos="fade-left"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="500"
                >
                    <div className="single_screenshot">
                        <img src={screenshot1} alt="" />
                    </div>

                </div>
                <div
                    className="single_screenshot_slider"
                    data-aos="fade-left"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="500"
                >
                    <div className="single_screenshot">
                        <img src={screenshot2} alt="" />
                    </div>

                </div>
                <div
                    className="single_screenshot_slider"
                    data-aos="fade-left"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="500"
                >
                    <div className="single_screenshot">
                        <img src={screenshot3} alt="" />
                    </div>

                </div>
                <div
                    className="single_screenshot_slider"
                    data-aos="fade-left"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="500"
                >
                    <div className="single_screenshot">
                        <img src={screenshot4} alt="" />
                    </div>
                </div>
                <div
                    className="single_screenshot_slider"
                    data-aos="fade-left"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="500"
                >
                    <div className="single_screenshot">
                        <img src={screenshot5} alt="" />
                    </div>
                </div>
                <div
                    className="single_screenshot_slider"
                    data-aos="fade-left"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="500"
                >
                    <div className="single_screenshot">
                        <img src={screenshot6} alt="" />
                    </div>
                </div>
            </Slider>
        </div>
        <div className={"websiteVersion"}>
            <Slider {...settings}>
                <div
                    className="single_screenshot_slider"
                    data-aos="fade-left"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="500"
                >
                    <div className="single_screenshot">
                        <img src={screenshot1} alt="" />
                    </div>
                    <div className="single_screenshot image-resize">
                        <img src={screenshot2} alt="" />
                    </div>
                    <div className="single_screenshot image-resize-two">
                        <img src={screenshot3} alt="" />
                    </div>
                </div>
                <div
                    className="single_screenshot_slider"
                    data-aos="fade-left"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="500"
                >
                    <div className="single_screenshot">
                        <img src={screenshot4} alt="" />
                    </div>
                    <div className="single_screenshot image-resize">
                        <img src={screenshot5} alt="" />
                    </div>
                    <div className="single_screenshot image-resize-two">
                        <img src={screenshot6} alt="" />
                    </div>
                </div>
            </Slider>
        </div>

    </div>
      </Element>
  );
};

export default Screenshots;
