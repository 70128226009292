import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer-section">
      <div>
        <h5>Legal</h5>
        <Link to="/privacy-policy">Privacy policy</Link>
      </div>
      <div>
        <h5>Support</h5>
        <a
          href="mailto:the.projekt.k@gmail.com?subject=Help%20Center"
          target="_blank" rel="noreferrer"
        >
          Help center
        </a>
      </div>
      <div>
        <h5>Social</h5>
        <a href="https://www.instagram.com/dyvide.app/" target="_blank" rel="noreferrer">
          Instagram
        </a>
      </div>
    </div>
  );
};

export default Footer;
