import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./About.css";
import sliderBg from "../../Assets/explicacio.jpeg";
import AOS from "aos";
import "aos/dist/aos.css";
import { Element } from 'react-scroll';
AOS.init();

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        background: "rgb(255, 164, 1)",
      }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        background: "rgb(255, 164, 1)",
      }}
      onClick={onClick}
    />
  );
}

const About = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (

      <Element name="about-scroll-div">
    <div id="about" className="about-section">
      <h6
        data-aos="fade-down"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="1000"
      >
        About the app
      </h6>
      <h1
        data-aos="flip-down"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="2000"
      >
        how it works
      </h1>
      <Slider {...settings}>
        <div className={"slider-item-space"}>
            <div className="single_slider">
                <div className="slider-bg">
                    <img
                        src={sliderBg}
                        alt=""
                        data-aos="fade-up"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="5000"
                    />
                </div>
                <div className="single_slider_content">
                    <h3
                        data-aos="fade-up"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="1000"
                    >
                        01
                    </h3>
                    <h4
                        data-aos="fade-up"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="1200"
                    >
                        Calculating your trip expenses can be a huge hassle. Dyvide makes
                        the process easy and fast.
                    </h4>
                    <p>
                        By using Dyvide you can take advantage of features like our Trip
                        Calculator to automatically calculate your fuel and toll expenses
                        for a specific trip
                    </p>
                </div>
            </div>
        </div>
          <div className={"slider-item-space"}>
        <div className="single_slider">
          <div className="slider-bg">
            <img src={sliderBg} alt="" />
          </div>
          <div className="single_slider_content">
            <h3>02</h3>
            <h4>
              Sharing you calculations and trip information with your friends .
            </h4>
            <p>
              Once you've calculated a trip you probably want to share it with
              your friends. We have made it very simple for our users to do so,
              by sharing both an image with information and a link to the app to
              view even more details.
            </p>
          </div>
        </div>
          </div>
          <div className={"slider-item-space"}>
        <div className="single_slider">
          <div className="slider-bg">
            <img src={sliderBg} alt="" />
          </div>
          <div className="single_slider_content">
            <h3>03</h3>
            <h4>
              Trips are stored for later use and can even be added to favorites
            </h4>
            <p>
              Whenever you calculate a trip you can choose to save it to your
              trip history, this way you don't have to recalculate this trip
              each time, and can quickly access the information you want to
              view.
            </p>
          </div>
        </div>
          </div>
      </Slider>
    </div>
      </Element>
  );
};

export default About;
