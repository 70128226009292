import React from "react";
import "./GetApp.css";
import { AiFillApple, AiOutlineGoogle } from "react-icons/ai";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const GetApp = () => {
  return (
    <div
      className="get-app-section"
      data-aos="fade-up"
      data-aos-easing="ease-out-cubic"
      data-aos-duration="1000"
    >
      <a
        href="https://apps.apple.com/us/app/dyvide/id1622209712"
        target="_blank" rel="noreferrer"
     
      >
        <div>
          <AiFillApple />
          <div>
            <p>Download on the</p>
            <h4>App store</h4>
          </div>
        </div>
      </a>

      <a
        href="https://play.google.com/store/apps/details?id=com.projectk.dyvide"
        target="_blank" rel="noreferrer"
        className={"android"}
      >
        <div>
          <AiOutlineGoogle />
          <div>
            <p>Get it on</p>
            <h4>Google play</h4>
          </div>
        </div>
      </a>
    </div>
  );
};

export default GetApp;
