import React from "react";
import "./PrivacyPolicy.css";
import logo from "../../Assets/logo.png";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-section">
      <div className="privacy-policy-heading">
        <img src={logo} alt="" />
        <h4>Dyvide - Privacy Policy</h4>
        <span>Last updated September 12, 2022</span>
      </div>

      <div className="privacy-policy-content">
        <p>
          This privacy notice for Project K ({" "}
          <span style={{ fontWeight: "bold" }}>
            " Company ," "we," "us," or "our"
          </span>{" "}
          ), describes how and why we might collect, store, use, and/or share{" "}
          <span style={{ fontWeight: "bold" }}>( "process" )</span> your
          information when you use our services{" "}
          <span style={{ fontWeight: "bold" }}>( "Services" )</span>, such as
          when you:{" "}
        </p>
        <ul>
          <li>
            Download and use our mobile application ( Dyvide) , or any other
            application of ours that links to this privacy notice{" "}
          </li>
          <li>
            Engage with us in other related ways, including any sales,
            marketing, or events{" "}
          </li>
        </ul>
        <p>
          <span style={{ fontWeight: "bold" }}>Questions or concerns?</span>{" "}
          Reading this privacy notice will help you understand your privacy
          rights and choices. If you do not agree with our policies and
          practices, please do not use our Services. If you still have any
          questions or concerns, please contact us at the.projekt.k@gmail.com .{" "}
        </p>

        <div className="summary-of-key-points">
          <h4>SUMMARY OF KEY POINTS</h4>
          <p>
            <span style={{ fontWeight: "bold", fontStyle: "italic" }}>
              This summary provides key points from our privacy notice, but you
              can find out more details about any of these topics by clicking
              the link following each key point or by using our table of
              contents below to find the section you are looking for. You can
              also click{" "}
              <a
                href="#table-content"
                style={{
                  color: "rgb(255, 164, 1)",
                  textDecoration: "underline",
                }}
              >
                here
              </a>{" "}
              to go directly to our table of contents.
            </span>
          </p>
          <p>
            {" "}
            <span style={{ fontWeight: "bold" }}>
              What personal information do we process?
            </span>{" "}
            When you visit, use, or navigate our Services, we may process
            personal information depending on how you interact with Project K
            and the Services, the choices you make, and the products and
            features you use. Click{" "}
            <a
              href="#disclose-personal-info"
              style={{ color: "rgb(255, 164, 1)", textDecoration: "underline" }}
            >
              here
            </a>{" "}
            to learn more.{" "}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>
              Do we process any sensitive personal information?
            </span>{" "}
            We do not process sensitive personal information.{" "}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>
              Do we receive any information from third parties?
            </span>{" "}
            We do not receive any information from third parties.{" "}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>
              How do we process your information?
            </span>{" "}
            We process your information to provide, improve, and administer our
            Services, communicate with you, for security and fraud prevention,
            and to comply with law. We may also process your information for
            other purposes with your consent. We process your information only
            when we have a valid legal reason to do so. Click{" "}
            <a
              href="#process-info"
              style={{ color: "rgb(255, 164, 1)", textDecoration: "underline" }}
            >
              here
            </a>{" "}
            to learn more.
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>
              In what situations and with which parties do we share personal
              information?
            </span>{" "}
            We may share information in specific situations and with specific
            third parties. Click{" "}
            <a
              href="#when-and-whom"
              style={{ color: "rgb(255, 164, 1)", textDecoration: "underline" }}
            >
              here
            </a>{" "}
            to learn more.{" "}
          </p>
          <p>
            {" "}
            <span style={{ fontWeight: "bold" }}>
              How do we keep your information safe?
            </span>{" "}
            We have organizational and technical processes and procedures in
            place to protect your personal information. However, no electronic
            transmission over the internet or information storage technology can
            be guaranteed to be 100% secure, so we cannot promise or guarantee
            that hackers, cybercriminals, or other unauthorized third parties
            will not be able to defeat our security and improperly collect,
            access, steal, or modify your information. Click{" "}
            <a
              href="#your-info-safe"
              style={{ color: "rgb(255, 164, 1)", textDecoration: "underline" }}
            >
              here
            </a>{" "}
            to learn more.{" "}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>What are your rights?</span>{" "}
            Depending on where you are located geographically, the applicable
            privacy law may mean you have certain rights regarding your personal
            information. Click{" "}
            <a
              href="#privacy-right"
              style={{ color: "rgb(255, 164, 1)", textDecoration: "underline" }}
            >
              here
            </a>{" "}
            to learn more.
          </p>
          <p>
            {" "}
            <span style={{ fontWeight: "bold" }}>
              How do you exercise your rights?
            </span>{" "}
            The easiest way to exercise your rights is by filling out our data
            subject request form available{" "}
            <a
              href="https://app.termly.io/notify/6fcce8b4-f26c-498f-9e13-8343b5fbf158"
              target="_blank"  rel="noreferrer"
              style={{ color: "rgb(255, 164, 1)", textDecoration: "underline" }}
            >
              here
            </a>{" "}
            , or by contacting us. We will consider and act upon any request in
            accordance with applicable data protection laws.{" "}
          </p>
          <p>
            Want to learn more about what Project K does with any information we
            collect? Click{" "}
            <a
              href="#what-information-we-collect"
              style={{ color: "rgb(255, 164, 1)", textDecoration: "underline" }}
            >
              here
            </a>{" "}
            to review the notice in full.
          </p>
        </div>

        <div id="table-content" className="table-of-contents">
          <h4>TABLE OF CONTENTS</h4>
          <a href="#informatio">1. WHAT INFORMATION DO WE COLLECT?</a>
          <a href="#process-info">2. HOW DO WE PROCESS YOUR INFORMATION? </a>
          <a href="#process-your-information">
            3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
            INFORMATION?
          </a>
          <a href="#when-and-whom">
            4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          </a>
          <a href="#our-stance-on">
            5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?{" "}
          </a>
          <a href="#use-cookies">
            6. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
          </a>
          <a href="#how-long-we-keep-info">
            7. HOW LONG DO WE KEEP YOUR INFORMATION?
          </a>
          <a href="#your-info-safe">8. HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
          <a href="#privacy-right">9. WHAT ARE YOUR PRIVACY RIGHTS?</a>
          <a href="#controls-for-features">
            10. CONTROLS FOR DO-NOT-TRACK FEATURES
          </a>
          <a href="#specific-right">
            11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
          </a>
          <a href="#update-notice">12. DO WE MAKE UPDATES TO THIS NOTICE?</a>
          <a href="#contact-us">
            13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
          </a>
          <a href="#update-delete-data">
            14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?
          </a>
        </div>

        <div id="informatio" className="what-information-we-collect">
          <h4 id="what-information-we-collect">
            1. WHAT INFORMATION DO WE COLLECT?
          </h4>
          <h6>Personal information you disclose to us</h6>
          <p>
            <span style={{ fontWeight: "bold" }}>In Short:</span>{" "}
            <span style={{ fontStyle: "italic" }}>
              We collect personal information that you provide to us.
            </span>
          </p>
          <p>
            We collect personal information that you voluntarily provide to us
            when you register on the Services, express an interest in obtaining
            information about us or our products and Services, when you
            participate in activities on the Services, or otherwise when you
            contact us.{" "}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>
              Personal Information Provided by You.
            </span>{" "}
            The personal information that we collect depends on the context of
            your interactions with us and the Services, the choices you make,
            and the products and features you use. The personal information we
            collect may include the following:{" "}
          </p>
          <ul>
            <li>usernames</li>
            <li>phone numbers</li>
          </ul>
          <p>
            <span style={{ fontWeight: "bold" }}>Sensitive Information.</span>{" "}
            We do not process sensitive information.{" "}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Application Data.</span> If you
            use our application(s), we also may collect the following
            information if you choose to provide us with access or permission:
          </p>
          <ul>
            <li>
              Geolocation Information. We may request access or permission to
              track location-based information from your mobile device, either
              continuously or while you are using our mobile application(s), to
              provide certain location-based services. If you wish to change our
              access or permissions, you may do so in your device's settings.
            </li>
            <li>
              Mobile Device Access. We may request access or permission to
              certain features from your mobile device, including your mobile
              device's camera, contacts, sms messages, and other features. If
              you wish to change our access or permissions, you may do so in
              your device's settings.
            </li>
            <li>
              Mobile Device Data. We automatically collect device information
              (such as your mobile device ID, model, and manufacturer),
              operating system, version information and system configuration
              information, device and application identification numbers,
              browser type and version, hardware model Internet service provider
              and/or mobile carrier, and Internet Protocol (IP) address (or
              proxy server). If you are using our application(s), we may also
              collect information about the phone network associated with your
              mobile device, your mobile device’s operating system or platform,
              the type of mobile device you use, your mobile device’s unique
              device ID, and information about the features of our
              application(s) you accessed.
            </li>
            <li>
              Push Notifications. We may request to send you push notifications
              regarding your account or certain features of the application(s).
              If you wish to opt out from receiving these types of
              communications, you may turn them off in your device's settings.
            </li>
          </ul>
          <p>
            This information is primarily needed to maintain the security and
            operation of our application(s), for troubleshooting, and for our
            internal analytics and reporting purposes.
          </p>
          <p>
            All personal information that you provide to us must be true,
            complete, and accurate, and you must notify us of any changes to
            such personal information.
          </p>
          <h6>Information automatically collected</h6>
          <p>
            <span style={{ fontWeight: "bold" }}>In Short:</span>{" "}
            <span style={{ fontStyle: "italic" }}>
              Some information — such as your Internet Protocol (IP) address
              and/or browser and device characteristics — is collected
              automatically when you visit our Services.
            </span>
          </p>
          <p>
            We automatically collect certain information when you visit, use, or
            navigate the Services. This information does not reveal your
            specific identity (like your name or contact information) but may
            include device and usage information, such as your IP address,
            browser and device characteristics, operating system, language
            preferences, referring URLs, device name, country, location,
            information about how and when you use our Services, and other
            technical information. This information is primarily needed to
            maintain the security and operation of our Services, and for our
            internal analytics and reporting purposes
          </p>
          <p>
            Like many businesses, we also collect information through cookies
            and similar technologies.{" "}
          </p>
          <p>The information we collect includes:</p>
          <ul>
            <li>
              <span style={{ fontWeight: "bold" }}>Log and Usage Data.</span>{" "}
              Log and usage data is service-related, diagnostic, usage, and
              performance information our servers automatically collect when you
              access or use our Services and which we record in log files.
              Depending on how you interact with us, this log data may include
              your IP address, device information, browser type, and settings
              and information about your activity in the Services (such as the
              date/time stamps associated with your usage, pages and files
              viewed, searches, and other actions you take such as which
              features you use), device event information (such as system
              activity, error reports (sometimes called "crash dumps"), and
              hardware settings).
            </li>
            <li>
              <span style={{ fontWeight: "bold" }}>Device Data.</span> We
              collect device data such as information about your computer,
              phone, tablet, or other device you use to access the Services.
              Depending on the device used, this device data may include
              information such as your IP address (or proxy server), device and
              application identification numbers, location, browser type,
              hardware model, Internet service provider and/or mobile carrier,
              operating system, and system configuration information.
            </li>
          </ul>
        </div>

        <div id="process-info" className="how-we-process-information">
          <h4>2. HOW DO WE PROCESS YOUR INFORMATION?</h4>
          <p>
            <span style={{ fontWeight: "bold" }}>In Short:</span>{" "}
            <span style={{ fontStyle: "italic" }}>
              We process your information to provide, improve, and administer
              our Services, communicate with you, for security and fraud
              prevention, and to comply with law. We may also process your
              information for other purposes with your consent.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>
              We process your personal information for a variety of reasons,
              depending on how you interact with our Services, including:
            </span>
          </p>
          <ul>
            <li>
              <span style={{ fontWeight: "bold" }}>
                To facilitate account creation and authentication and otherwise
                manage user accounts.
              </span>{" "}
              We may process your information so you can create and log in to
              your account, as well as keep your account in working order.{" "}
            </li>
            <li>
              <span style={{ fontWeight: "bold" }}>
                To save or protect an individual's vital interest.
              </span>{" "}
              We may process your information when necessary to save or protect
              an individual’s vital interest, such as to prevent harm.
            </li>
          </ul>
        </div>

        <div id="process-your-information" className="process-your-info">
          <h4>
            3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
          </h4>
          <p>
            <span style={{ fontWeight: "bold" }}>In Short:</span>{" "}
            <span style={{ fontStyle: "italic" }}>
              {" "}
              We only process your personal information when we believe it is
              necessary and we have a valid legal reason (i.e. , legal basis) to
              do so under applicable law, like with your consent, to comply with
              laws, to provide you with services to enter into or fulfill our
              contractual obligations, to protect your rights, or to fulfill our
              legitimate business interests.{" "}
            </span>
          </p>
          <p>
            The General Data Protection Regulation (GDPR) and UK GDPR require us
            to explain the valid legal bases we rely on in order to process your
            personal information. As such, we may rely on the following legal
            bases to process your personal information:
          </p>
          <ul>
            <li>
              <span style={{ fontWeight: "bold" }}>Consent.</span> We may
              process your information if you have given us permission (i.e. ,
              consent) to use your personal information for a specific purpose.
              You can withdraw your consent at any time. Click{" "}
              <a
                href="#information-about-account"
                style={{
                  color: "rgb(255, 164, 1)",
                  textDecoration: "underline",
                }}
              >
                here
              </a>{" "}
              to learn more.
            </li>
            <li>
              <span style={{ fontWeight: "bold" }}>Legal Obligations.</span> We
              may process your information where we believe it is necessary for
              compliance with our legal obligations, such as to cooperate with a
              law enforcement body or regulatory agency, exercise or defend our
              legal rights, or disclose your information as evidence in
              litigation in which we are involved.{" "}
            </li>
            <li>
              <span style={{ fontWeight: "bold" }}> Vital Interests.</span> We
              may process your information where we believe it is necessary to
              protect your vital interests or the vital interests of a third
              party, such as situations involving potential threats to the
              safety of any person.
            </li>
          </ul>
        </div>

        <div id="when-and-whom" className="pesonal-information">
          <h4>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h4>
          <p>
            {" "}
            <span style={{ fontWeight: "bold" }}>In Short:</span>{" "}
            <span style={{ fontStyle: "italic" }}>
              We may share information in specific situations described in this
              section and/or with the following third parties.{" "}
            </span>
          </p>
          <p>
            We may need to share your personal information in the following
            situations:{" "}
          </p>
          <ul>
            <li>
              <span style={{ fontWeight: "bold" }}>Business Transfers.</span> We
              may share or transfer your information in connection with, or
              during negotiations of, any merger, sale of company assets,
              financing, or acquisition of all or a portion of our business to
              another company.
            </li>
            <li>
              {" "}
              <span style={{ fontWeight: "bold" }}>
                When we use Google Maps Platform APIs.
              </span>{" "}
              We may share your information with certain Google Maps Platform
              APIs (e.g. , Google Maps API, Places API). To find out more about
              Google’s Privacy Policy, please refer to this{" "}
              <a
                href="https://policies.google.com/privacy"
                target="_blank"  rel="noreferrer"
                style={{
                  color: "rgb(255, 164, 1)",
                  textDecoration: "underline",
                }}
              >
                link
              </a>{" "}
              . We obtain and store on your device ( "cache" ) your location .
              You may revoke your consent anytime by contacting us at the
              contact details provided at the end of this document.{" "}
            </li>
            <li>
              <span style={{ fontWeight: "bold" }}>Affiliates.</span> We may
              share your information with our affiliates, in which case we will
              require those affiliates to honor this privacy notice. Affiliates
              include our parent company and any subsidiaries, joint venture
              partners, or other companies that we control or that are under
              common control with us.{" "}
            </li>
            <li>
              <span style={{ fontWeight: "bold" }}>Business Partners.</span> We
              may share your information with our business partners to offer you
              certain products, services, or promotions.
            </li>
            <li>
              <span style={{ fontWeight: "bold" }}>Offer Wall.</span> Our
              application(s) may display a third-party hosted "offer wall." Such
              an offer wall allows third-party advertisers to offer virtual
              currency, gifts, or other items to users in return for the
              acceptance and completion of an advertisement offer. Such an offer
              wall may appear in our application(s) and be displayed to you
              based on certain data, such as your geographic area or demographic
              information. When you click on an offer wall, you will be brought
              to an external website belonging to other persons and will leave
              our application(s). A unique identifier, such as your user ID,
              will be shared with the offer wall provider in order to prevent
              fraud and properly credit your account with the relevant reward.{" "}
            </li>
          </ul>
        </div>

        <div id="our-stance-on" className="our-stance">
          <h4>5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</h4>
          <p>
            <span style={{ fontWeight: "bold" }}>In Short:</span>{" "}
            <span style={{ fontStyle: "italic" }}>
              We are not responsible for the safety of any information that you
              share with third parties that we may link to or who advertise on
              our Services, but are not affiliated with, our Services.
            </span>
          </p>
          <p>
            The Services , including our offer wall, may link to third-party
            websites, online services, or mobile applications and/or contain
            advertisements from third parties that are not affiliated with us
            and which may link to other websites, services, or applications.
            Accordingly, we do not make any guarantee regarding any such third
            parties, and we will not be liable for any loss or damage caused by
            the use of such third-party websites, services, or applications. The
            inclusion of a link towards a third-party website, service, or
            application does not imply an endorsement by us. We cannot guarantee
            the safety and privacy of data you provide to any third parties. Any
            data collected by third parties is not covered by this privacy
            notice. We are not responsible for the content or privacy and
            security practices and policies of any third parties, including
            other websites, services, or applications that may be linked to or
            from the Services. You should review the policies of such third
            parties and contact them directly to respond to your questions.{" "}
          </p>
        </div>

        <div id="use-cookies" className="do-we-use-cookies">
          <h4>6. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h4>
          <p>
            <span style={{ fontWeight: "bold" }}>In Short:</span>{" "}
            <span style={{ fontStyle: "italic" }}>
              We may use cookies and other tracking technologies to collect and
              store your information.
            </span>
          </p>
          <p>
            We may use cookies and similar tracking technologies (like web
            beacons and pixels) to access or store information. Specific
            information about how we use such technologies and how you can
            refuse certain cookies is set out in our Cookie Notice .{" "}
          </p>
        </div>

        <div id="how-long-we-keep-info" className="do-we-keep-info">
          <h4>7. HOW LONG DO WE KEEP YOUR INFORMATION?</h4>
          <p>
            <span style={{ fontWeight: "bold" }}>In Short:</span>{" "}
            <span style={{ fontStyle: "italic" }}>
              We keep your information for as long as necessary to fulfill the
              purposes outlined in this privacy notice unless otherwise required
              by law.
            </span>
          </p>
          <p>
            We will only keep your personal information for as long as it is
            necessary for the purposes set out in this privacy notice, unless a
            longer retention period is required or permitted by law (such as
            tax, accounting, or other legal requirements). No purpose in this
            notice will require us keeping your personal information for longer
            than the period of time in which users have an account with us.
          </p>
          <p>
            When we have no ongoing legitimate business need to process your
            personal information, we will either delete or anonymize such
            information, or, if this is not possible (for example, because your
            personal information has been stored in backup archives), then we
            will securely store your personal information and isolate it from
            any further processing until deletion is possible.
          </p>
        </div>

        <div id="your-info-safe" className="keep-info-safe">
          <h4>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</h4>
          <p>
            <span style={{ fontWeight: "bold" }}>In Short:</span>{" "}
            <span style={{ fontStyle: "italic" }}>
              We aim to protect your personal information through a system of
              organizational and technical security measures.
            </span>
          </p>
          <p>
            We have implemented appropriate and reasonable technical and
            organizational security measures designed to protect the security of
            any personal information we process. However, despite our safeguards
            and efforts to secure your information, no electronic transmission
            over the Internet or information storage technology can be
            guaranteed to be 100% secure, so we cannot promise or guarantee that
            hackers, cybercriminals, or other unauthorized third parties will
            not be able to defeat our security and improperly collect, access,
            steal, or modify your information. Although we will do our best to
            protect your personal information, transmission of personal
            information to and from our Services is at your own risk. You should
            only access the Services within a secure environment.
          </p>
        </div>

        <div id="privacy-right" className="privacy-right">
          <h4>9. WHAT ARE YOUR PRIVACY RIGHTS?</h4>
          <p>
            <span style={{ fontWeight: "bold" }}>In Short:</span>{" "}
            <span style={{ fontStyle: "italic" }}>
              In some regions, such as the European Economic Area (EEA) and
              United Kingdom (UK), you have rights that allow you greater access
              to and control over your personal information. You may review,
              change, or terminate your account at any time.
            </span>
          </p>
          <p>
            In some regions (like the EEA and UK), you have certain rights under
            applicable data protection laws. These may include the right (i) to
            request access and obtain a copy of your personal information, (ii)
            to request rectification or erasure; (iii) to restrict the
            processing of your personal information; and (iv) if applicable, to
            data portability. In certain circumstances, you may also have the
            right to object to the processing of your personal information. You
            can make such a request by contacting us by using the contact
            details provided in the section{" "}
            <a
              href="#contact-us"
              style={{ color: "rgb(255, 164, 1)", textDecoration: "underline" }}
            >
              "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
            </a>{" "}
            below.
          </p>
          <p>
            We will consider and act upon any request in accordance with
            applicable data protection laws. If you are located in the EEA or UK
            and you believe we are unlawfully processing your personal
            information, you also have the right to complain to your local data
            protection supervisory authority. You can find their contact details
            here:{" "}
            <a
              href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm."
              target="_blank"  rel="noreferrer"
              style={{ color: "rgb(255, 164, 1)", textDecoration: "underline" }}
            >
              https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
            </a>
          </p>
          <p>
            If you are located in Switzerland, the contact details for the data
            protection authorities are available here:{" "}
            <a
              href="https://www.edoeb.admin.ch/edoeb/en/home.html"
              target="_blank"  rel="noreferrer"
              style={{ color: "rgb(255, 164, 1)", textDecoration: "underline" }}
            >
              https://www.edoeb.admin.ch/edoeb/en/home.html.
            </a>
          </p>
          <p>
            Withdrawing your consent: If we are relying on your consent to
            process your personal information, you have the right to withdraw
            your consent at any time. You can withdraw your consent at any time
            by contacting us by using the contact details provided in the
            section{" "}
            <a
              href="#contact-us"
              style={{ color: "rgb(255, 164, 1)", textDecoration: "underline" }}
            >
              "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
            </a>{" "}
            below or updating your preferences.
          </p>
          <p>
            However, please note that this will not affect the lawfulness of the
            processing before its withdrawal nor, will it affect the processing
            of your personal information conducted in reliance on lawful
            processing grounds other than consent.
          </p>
          <h6 id="information-about-account">Account Information</h6>
          <p>
            If you would at any time like to review or change the information in
            your account or terminate your account, you can:
          </p>
          <ul>
            <li>
              Log in to your account settings and update your user account.
            </li>
          </ul>
          <p>
            Upon your request to terminate your account, we will deactivate or
            delete your account and information from our active databases.
            However, we may retain some information in our files to prevent
            fraud, troubleshoot problems, assist with any investigations,
            enforce our legal terms and/or comply with applicable legal
            requirements.
          </p>
          <p>
            If you have questions or comments about your privacy rights, you may
            email us at the.projekt.k@gmail.com.
          </p>
        </div>

        <div id="controls-for-features" className="do-not-tract-feature">
          <h4>10. CONTROLS FOR DO-NOT-TRACK FEATURES</h4>
          <p>
            Most web browsers and some mobile operating systems and mobile
            applications include a Do-Not-Track ("DNT") feature or setting you
            can activate to signal your privacy preference not to have data
            about your online browsing activities monitored and collected. At
            this stage no uniform technology standard for recognizing and
            implementing DNT signals has been finalized. As such, we do not
            currently respond to DNT browser signals or any other mechanism that
            automatically communicates your choice not to be tracked online. If
            a standard for online tracking is adopted that we must follow in the
            future, we will inform you about that practice in a revised version
            of this privacy notice.
          </p>
        </div>

        <div id="specific-right" className="specific-privacy-right">
          <h4>11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h4>
          <p>
            <span style={{ fontWeight: "bold" }}>In Short:</span>{" "}
            <span style={{ fontStyle: "italic" }}>
              Yes, if you are a resident of California, you are granted specific
              rights regarding access to your personal information.
            </span>
          </p>
          <p>
            California Civil Code Section 1798.83, also known as the "Shine The
            Light" law, permits our users who are California residents to
            request and obtain from us, once a year and free of charge,
            information about categories of personal information (if any) we
            disclosed to third parties for direct marketing purposes and the
            names and addresses of all third parties with which we shared
            personal information in the immediately preceding calendar year. If
            you are a California resident and would like to make such a request,
            please submit your request in writing to us using the contact
            information provided below.
          </p>
          <p>
            If you are under 18 years of age, reside in California, and have a
            registered account with Services, you have the right to request
            removal of unwanted data that you publicly post on the Services. To
            request removal of such data, please contact us using the contact
            information provided below and include the email address associated
            with your account and a statement that you reside in California. We
            will make sure the data is not publicly displayed on the Services,
            but please be aware that the data may not be completely or
            comprehensively removed from all our systems (e.g., backups, etc.).
          </p>
        </div>

        <div id="update-notice" className="notice">
          <h4>12. DO WE MAKE UPDATES TO THIS NOTICE?</h4>
          <p>
            <span style={{ fontWeight: "bold" }}>In Short:</span>{" "}
            <span style={{ fontStyle: "italic" }}>
              Yes, we will update this notice as necessary to stay compliant
              with relevant laws.
            </span>
          </p>
          <p>
            We may update this privacy notice from time to time. The updated
            version will be indicated by an updated "Revised" date and the
            updated version will be effective as soon as it is accessible. If we
            make material changes to this privacy notice, we may notify you
            either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this
            privacy notice frequently to be informed of how we are protecting
            your information.{" "}
          </p>
        </div>

        <div id="contact-us" className="about-notice">
          <h4>13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h4>
          <p>
            If you have questions or comments about this notice, you may email
            us at the.projekt.k@gmail.com or by post to:
          </p>
          <span>Project K</span> <br />
          <span>Barcelona </span> <br />
          <span>Barcelona </span> <br />
          <span>Spain</span> <br />
        </div>

        <div id="update-delete-data" className="review-update-delete">
          <h4>
            14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?
          </h4>
          <p>
            Based on the applicable laws of your country, you may have the right
            to request access to the personal information we collect from you,
            change that information, or delete it. To request to review, update,
            or delete your personal information, please submit a request form by
            clicking{" "}
            <a
              href="https://app.termly.io/notify/6fcce8b4-f26c-498f-9e13-8343b5fbf158"
              target="_blank"  rel="noreferrer"
              style={{ color: "rgb(255, 164, 1)", textDecoration: "underline" }}
            >
              here
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
