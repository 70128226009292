import React from "react";
import "./Features.css";
import { MdLocationOn } from "react-icons/md";
import { BsCalculator } from "react-icons/bs";
import { FaUsers, FaSuitcase } from "react-icons/fa";
import { AiFillCar } from "react-icons/ai";
import AOS from "aos";
import "aos/dist/aos.css";
import { Element } from 'react-scroll';
AOS.init();

const Features = () => {
  return (
      <Element name="features-scroll-div">
    <div id="appfeatures" className="app-feature-section">
      <h6
        data-aos="fade-down"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="1000"
      >
        app features
      </h6>
      <h1
        data-aos="flip-down"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="2000"
      >
        made for users
      </h1>

      <div className="feature_calculator">
        <div
          data-aos="fade-right"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1000"
        >
          <MdLocationOn />
          <h4>Trip calculator</h4>
          <p>
            Automatically calculate the total distance between waypoints, the
            tolls you might go through, all the alternative routes and the total
            cost of each of them.
          </p>
        </div>
        <div
          data-aos="fade-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1000"
        >
          <BsCalculator />
          <h4>calculator</h4>
          <p>
            Calculate the cost of a specific distance, the necessary fuel to
            achieve said distance and even calculate the consumption of your
            vehicle.
          </p>
        </div>
      </div>

      <div className="app-features">
        <div
          data-aos="flip-down"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1200"
        >
          <FaUsers />
          <h4>friends</h4>
          <p>
            See what your friends are up to by sharing your calculations with
            them.
          </p>
        </div>
        <div
          data-aos="flip-down"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1200"
        >
          <AiFillCar />
          <h4>Vehicles</h4>
          <p>
            Store your vehicle information on the app for detailed calculations
            on your trips.
          </p>
        </div>
        <div
          data-aos="flip-down"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1200"
        >
          <FaSuitcase />
          <h4>Instant trip</h4>
          <p>
            Create custom locations to easily calculate trips to them whenever
            you need to.
          </p>
        </div>
      </div>
    </div>
      </Element>
  );
};

export default Features;
