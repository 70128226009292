import "./Header.css";
import logo from "../../../src/Assets/icon.png";
import screen1 from "../../Assets/screen3.jpeg";
import screen2 from "../../Assets/header2-upscaled.jpeg";
import screen3 from "../../Assets/header3.jpeg";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from 'react-scroll';
AOS.init();


const Header = () => {


  return (
    <div id="headerSection" className="header-section">
      <div className="navbar-section">
        <div className="logo">
          <img src={logo} alt="" />
        </div>
        <nav>
          <ul>
            <li>
              <Link to="download-scroll-div" smooth={true} duration={1000} offset={-50}><button className={"availiOSAndroid"}>Available on iOS and Android</button></Link>
            </li>
            <li>
              <Link to="download-scroll-div" smooth={true} duration={1000} offset={-50}><button className={"downloadButton"}>download</button></Link>
            </li>

          </ul>
        </nav>
      </div>


      <div className="welcome-to-dyvide">
        <h6 className={"title"}
          data-aos="flip-down"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1000"
        >
          the best trip calculator
        </h6>
        <h1 className={"subtitle"}
          data-aos="flip-down"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
        >
          welcome to dyvide
        </h1>

        <div
          className="header-screens"
          data-aos="fade-up"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
        >
          <img src={screen1} alt="" />
          <img src={screen2} alt="" />
          <img src={screen3} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Header;
